<template>
  <div>
    <div v-if="items" class="card">
      <b-table :fields="fields" :items="items" :empty-text="$t('common.table.no_items')" show-empty>
        <template #cell(token)="data">
          <b-button
            variant="flat-secondary"
            v-clipboard:copy="data.item.token"
            v-clipboard:success="onCopy"
          >
            {{ data.item.token }}
          </b-button>
        </template>

        <template #cell(level)="data">
          <b-badge
            class="px-2"
            pill
            :variant="getLevelVariant(data.item.level)"
          >
            {{ data.item.level }}
          </b-badge>
        </template>

        <template #cell(origin)="data">
          <b-badge
            class="px-2"
            pill
            :variant="getOriginVariant(data.item.origin)"
          >
            {{ data.item.origin }}
          </b-badge>
        </template>

        <template #cell(created_at)="data">
          {{ convertDate(data.item.created_at) }}
        </template>

        <template #cell(actions)="data">
          <b-button variant="outline-info" @click="showDetails(data.item)">
            <feather-icon icon="PlusIcon" />
            {{ $t("transmission.log.table.details") }}
          </b-button>
        </template>
      </b-table>
      <b-modal
        :id="getID('details-modal')"
        ok-only
        :ok-title="$t('transmission.log.table.close')"
        centered
        size="xl"
        :title="$t('transmission.log.table.log_details')"
      >
        <b-card-text v-if="viewingItem">
          <div class="d-lg-flex justify-content-between">
            <div class="d-block d-lg-inline-block">
              <p class="p-0 m-0 small text-uppercase">{{ $t("transmission.log.table._id") }}</p>
              <p class="font-weight-bold text-primary">{{ viewingItem._id }}</p>
            </div>
            <div class="d-block d-lg-inline-block ml-lg-2">
              <p class="p-0 m-0 small text-uppercase">{{ $t("transmission.log.table.token") }}</p>
              <p class="font-weight-bold text-primary">
                {{ viewingItem.token }}
              </p>
            </div>
            <div class="d-block d-lg-inline-block ml-lg-2">
              <p class="p-0 m-0 small text-uppercase">{{ $t("transmission.log.table.origin") }}</p>
              <p class="font-weight-bold text-primary">
                {{ viewingItem.origin }}
              </p>
            </div>
            <div class="d-block d-lg-inline-block ml-lg-2">
              <p class="p-0 m-0 small text-uppercase">{{ $t("transmission.log.table.level") }}</p>
              <p class="font-weight-bold text-primary">
                {{ viewingItem.level }}
              </p>
            </div>
            <div class="d-block d-lg-inline-block ml-lg-2">
              <p class="p-0 m-0 small text-uppercase">{{ $t("transmission.log.table.created_at") }}</p>
              <p class="font-weight-bold text-primary">
                {{ convertDate(viewingItem.created_at) }}
              </p>
            </div>
          </div>
          <div>
            <json-viewer
              :value="getJsonData(viewingItem.data)"
              :expand-depth="5"
              copyable
              boxed
              :theme="`jv-${$store.state.appConfig.layout.skin}`"
              sort
            ></json-viewer>
            
          </div>
          <div>
            <h3 class="h3-separator mt-2 mb-0 text-center">Occurrences</h3>
          </div>
          <div v-if="viewingItem.occurrences" class="card">
            <b-table :fields="fields" :items="viewingItem.occurrences">
              <template #cell(token)="data">
                <b-button
                  variant="flat-secondary"
                  v-clipboard:copy="data.item._id"
                  v-clipboard:success="onCopy"
                >
                  {{ data.item._id }}
                </b-button>
              </template>

              <template #cell(level)="data">
                <b-badge
                  class="px-2"
                  pill
                  :variant="getLevelVariant(data.item.level)"
                >
                  {{ data.item.level }}
                </b-badge>
              </template>

              <template #cell(origin)="data">
                <b-badge
                  class="px-2"
                  pill
                  :variant="getOriginVariant(data.item.origin)"
                >
                  {{ data.item.origin }}
                </b-badge>
              </template>

              <template #cell(created_at)="data">
                {{ convertDate(data.item.created_at) }}
              </template>

              <template #cell(actions)="row">
                <b-button variant="outline-info" class="btn-icon" @click="row.toggleDetails">
                  <feather-icon :icon="row.detailsShowing ? 'MinusIcon' : 'PlusIcon' " />
                  {{ $t("transmission.log.table.details") }}
                </b-button>
              </template>

              <template #row-details="row">
                <b-card>
                  <b-row class="mb-2">
                    <b-col>
                      <json-viewer
                        
                        :value="getJsonData(row.item.data)"
                        :expand-depth="5"
                        copyable
                        boxed
                        :theme="`jv-${$store.state.appConfig.layout.skin}`"
                        sort
                      ></json-viewer>
                    </b-col>
                  </b-row>
                </b-card>
              </template>
            </b-table>
          </div>
        </b-card-text>
      </b-modal>

      <div class="search-more text-center p-2">
        <b-spinner
          v-if="searchingNextPage"
          type="grow"
          variant="primary"
          :label="$t('common.terms.loading')"
        ></b-spinner>
      </div>
    </div>
    <div v-else>
      <b-skeleton-table
        :rows="5"
        :columns="Object.keys(fields).lenght"
        :table-props="{ bordered: false, striped: true }"
      ></b-skeleton-table>
    </div>
  </div>
</template>

<script>
import { BTable, BProgress, BBadge, BSpinner, BButton, BCardText, BModal, BRow, BCol, BSkeletonTable } from "bootstrap-vue";
import { mapActions } from "vuex";
import moment from "moment";
import { makeToast } from "@/layouts/components/Popups.js";
import { v4 as uuidv4 } from "uuid";
import JsonViewer from "vue-json-viewer";

export default {
  components: {
    BTable,
    BProgress,
    BBadge,
    BSpinner,
    BButton,
    JsonViewer,
    BCardText,
    BModal,
    BRow,
    BCol,
    BSkeletonTable,
  },
  data() {
    return {
      fields: [
        { key: "token", label: this.$t("transmission.log.table.token") },
        { key: "origin", label: this.$t("transmission.log.table.origin") },
        { key: "level", label: this.$t("transmission.log.table.level") },
        {
          key: "created_at",
          label: this.$t("transmission.log.table.created_at"),
        },
        {
          key: "actions",
          label: this.$t("transmission.log.table.actions"),
        },
      ],
      items: undefined,
      cursor: undefined,
      searchingNextPage: false,
      uuidMap: {},
      viewingItem: undefined,
    };
  },
  mounted() {
    this.init();
    this.scroll();
  },
  methods: {
    ...mapActions([ "getRequestLogs", "getRequestOccurrencesLogs", "setLoadingState" ]),
    init() {
      this.getRequestLogs({ ...this.$route.params })
        .then((resp) => {
          this.items = resp.items;
          this.cursor = resp.cursor._id;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    goNextPage() {
      this.getRequestLogs({ previous_id: this.cursor, ...this.$route.params })
        .then((resp) => {
          this.items.push(...resp.items);
          this.cursor = resp.cursor._id;
          this.searchingNextPage = false;
        })
        .catch((err) => {
          console.log(err);
          this.searchingNextPage = false;
        });
    },
    isScrolledIntoView(el) {
      let rect = el.getBoundingClientRect();
      let elemTop = rect.top;
      let elemBottom = rect.bottom;

      let isVisible = elemTop < window.innerHeight && elemBottom >= 0;

      return isVisible;
    },
    scroll() {
      window.onscroll = () => {
        let scrolledTo = document.querySelector(".search-more");

        if (
          scrolledTo &&
          this.isScrolledIntoView(scrolledTo) &&
          !this.searchingNextPage &&
          this.cursor
        ) {
          this.searchingNextPage = true;
          this.goNextPage();
        }
      };
    },
    getLevelVariant(level) {
      const map = {
        info: "info",
        warning: "warning",
        error: "danger",
      };
      return map[level] || "secondary";
    },
    getOriginVariant(origin) {
      const map = {
        webhook: "light-primary",
        sync: "light-info",
        async: "light-warning",
      };
      return map[origin] || "secondary";
    },
    convertDate(date) {
      return moment.unix(date).format("LLL");
    },
    onCopy() {
      makeToast({
        title: this.$t("common.toast.copy_to_clipboard.success.title"),
        text: this.$t("common.toast.copy_to_clipboard.success.message"),
        variant: "success",
        icon: "CheckIcon",
      });
    },
    showDetails(item) {
      this.setLoadingState(true);

      this.viewingItem = item;
      this.getRequestOccurrencesLogs({ token: item.token, ...this.$route.params }).then((res) => {
        this.setLoadingState(false);
        this.$set(this.viewingItem, "occurrences", res.items);

        this.$bvModal.show(this.getID("details-modal"));
      }).catch((err) => {
        this.setLoadingState(false);
        console.log(err);
      });      
    },
    getID(key) {
      if (this.uuidMap[key]) {
        return this.uuidMap[key];
      }

      const uuid = uuidv4();
      this.uuidMap[key] = uuid;

      return uuid;
    },
    getJsonData(json) {
      const jsonData = JSON.parse(json);
      return jsonData;
    },
  },
};
</script>
 
<style lang="scss">
@import '@/assets/scss/variables/_variables.scss';
@import '@core/scss/base/bootstrap-extended/_variables.scss';

.jv-dark {
  background: #161d31;
  white-space: nowrap;
  color: $white;
  font-size: 14px;
  font-family: Consolas, Menlo, Courier, monospace;
  box-shadow: 0 2px 7px rgb(0 0 0 / 15%) !important;
  border-color: transparent !important;
  position: relative !important;

  .jv-ellipsis {
    color: $white;
    background-color: #293148;
    display: inline-block;
    line-height: 0.9;
    font-size: 0.9em;
    padding: 5px 4px 2px 4px;
    border-radius: 3px;
    vertical-align: 2px;
    cursor: pointer;
    user-select: none;
  }

  .jv-button {
    color: #49b3ff;
  }
  .jv-key {
    color: #a6e22e;
    padding-right: 5px;
  }
  .jv-item {
    &.jv-array {
      color: $white;
    }
    &.jv-boolean {
      color: #ae81ff;
    }
    &.jv-function {
      color: #067bca;
    }
    &.jv-number {
      color: #ae81ff;
    }
    &.jv-number-float {
      color: #ae81ff;
    }
    &.jv-number-integer {
      color: #ae81ff;
    }
    &.jv-object {
      color: $white;
    }
    &.jv-undefined {
      color: #e08331;
    }
    &.jv-string {
      color: #e6db74;
      word-break: break-word;
      white-space: normal;
    }
  }
  .jv-code {
    .jv-toggle {
      &:before {
        padding: 0px 2px;
        border-radius: 2px;
      }
      &:hover {
        &:before {
          background: #161d31;
        }
      }
    }
  }
}

.h3-separator {
  color: #ffffff2b !important;
  font-size: 40px;
}
</style>